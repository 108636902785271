<template>
  <HeaderBlock :isHideSearch="true" />
  <section class="questionnaire-section" v-if="attempt">
    <a class="page-back" href="/" v-if="question">
      <span>Вернуться на главную</span>
    </a>
    <div class="questionnaire-question" v-if="question">
      <div class="question-value">{{ question.value }}</div>
      <div v-if="question.isCheckbox" class="question-description">
        можно выбрать несколько вариантов
      </div>
      <div
        class="answers"
        v-if="question.answers"
        :class="{
          'answers-with-checkbox': question.isCheckbox,
          'answers-with-images': question.isImages,
        }"
      >
        <div
          v-for="item in question.answers"
          :key="item"
          class="answer-value"
          :class="{ active: item.isChecked }"
          @click.stop="clickAnswer(item)"
        >
          <img
            v-if="question.isImages && item.image"
            :src="item.image.image_object"
          />
          <span>{{ item.value }}</span>
        </div>
      </div>
      <div class="buttons">
        <button v-if="question.isShowButton" @click.stop="clickAnswer()">
          Продолжить
        </button>
        <span v-if="question.parentId">
          <a @click.stop="clickPrevQuestion()">Предыдущий вопрос</a>
        </span>
      </div>
    </div>
    <div v-if="attempt.status === 2" class="attempt-result">
      <h1>Результат подбора</h1>
      <h2>персональный рецепт № {{ attempt.id }}</h2>

      <div class="attempt-recipe">
        <h4>Прием утром до еды</h4>
        <div class="attempt-recipe-header">
          <span>ЯНВ</span>
          <span>ФЕВ</span>
          <span>МАР</span>
          <span>АПР</span>
          <span>МАЙ</span>
          <span>ИЮН</span>
          <span>ИЮЛ</span>
          <span>АВГ</span>
          <span>СЕН</span>
          <span>ОКТ</span>
          <span>НОЯ</span>
          <span>ДЕК</span>
        </div>
        <div class="attempt-recipe-items">
          <div class="attempt-recipe-item">
            <div class="left">
              <img
                src="https://api.ultrabalance.ru/media/product/ac1bcb0f0ca1dfb8a3e8f7f4aaf0ac59cropp2399_middle.jpg"
              />
            </div>
            <div class="right">
              <span class="month"></span>
              <span class="month"></span>
              <span class="month"></span>
              <span class="month"></span>
              <span class="month"></span>
              <span class="month"></span>
              <span class="month"></span>
              <span class="month"></span>
              <span class="month"></span>
              <span class="month"></span>
              <span class="month"></span>
              <span class="month"></span>
              <div class="period-items">
                <div class="period-item">
                  1 КАПСУЛА
                  <span>1, 2</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <FooterBlock />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import api from "@/api";
import "@/assets/css/questionnaire.css";

export default {
  mixins: [mainMixin, profileMixin],
  components: {
    HeaderBlock,
    FooterBlock,
  },
  data() {
    return {
      slug: null,
      attempt: null,
      questionnaire: null,
      question: null,
      blocks: [],
      stories: {},
    };
  },
  async mounted() {
    try {
      this.slug = this.$route.params.path_attempt;
      const result = await api.profile.getQuestionnaireAttempt(this.slug);
      let attempt = result["data"]["results"][0];
      if (attempt) this.attempt = attempt;
      else window.location.href = "/";
      this.questionnaire = attempt["questionnaire"];
      document.title = this.questionnaire["shop_page"][0]["title"];
      document.getElementsByTagName("meta")["description"].content =
        this.questionnaire["shop_page"][0]["meta_description"];
      for (let item in attempt["stories"]) {
        item = attempt["stories"][item];
        this.stories[item["question_id"]] = item["answers"];
      }
      this.generationQuestionnaire();
      this.getCurrentQuestion();
    } catch (error) {
      window.location.href = "/";
    }
  },
  methods: {
    generationQuestionnaire() {
      let answers = {};
      for (let item in this.questionnaire.blocks) {
        item = this.questionnaire.blocks[item];
        if (item["type"] === 2 && item["parents"].length > 0) {
          if (!answers[item["parents"][0]]) {
            answers[item["parents"][0]] = [];
          }
          answers[item["parents"][0]].push({
            id: item["id"],
            value: item["value"],
            image: item["image"],
            isChecked: false,
          });
        }
      }

      for (let item in this.questionnaire.blocks) {
        item = this.questionnaire.blocks[item];
        if (item["type"] === 1) {
          let isImages = true;
          for (let answer in answers[item["id"]]) {
            if (!answers[item["id"]][answer]["image"]) {
              isImages = false;
              break;
            }
          }
          let parentId = null;
          if (item["parents"]) parentId = item["parents"][0];
          this.blocks.push({
            id: item["id"],
            value: item["value"],
            isCheckbox: item["is_several_options"],
            isImages: isImages,
            answers: answers[item["id"]],
            parentId: parentId,
            isShowButton: false,
          });
        }
      }
    },
    clickAnswer(answer = null) {
      if (!answer) {
        this.stories[this.question.id] = [];
        for (let item in this.question.answers) {
          if (this.question.answers[item].isChecked) {
            this.stories[this.question.id].push(this.question.answers[item].id);
          }
        }
        this.getCurrentQuestion();
        this.saveStory(this.question.id, this.stories[this.question.id]);
        return false;
      }
      this.question.isShowButton = false;
      if (!this.question.isCheckbox) {
        for (let item in this.question.answers) {
          this.question.answers[item].isChecked = false;
        }
        answer.isChecked = true;
        this.question.isShowButton = true;
      } else {
        if (answer.isChecked) answer.isChecked = false;
        else {
          answer.isChecked = true;
          this.question.isShowButton = true;
        }
      }
      if (!this.question.isCheckbox) {
        this.stories[this.question.id] = [answer.id];
        this.getCurrentQuestion();
        this.saveStory(this.question.id, [answer.id]);
      }
    },
    clickPrevQuestion() {
      let parent = null;
      for (let item in this.blocks) {
        if (parent) break;
        if (this.blocks[item].id === this.question.parentId) {
          parent = this.blocks[item];
        } else {
          for (let answer in this.blocks[item].answers) {
            if (
              this.blocks[item].answers[answer].id === this.question.parentId
            ) {
              parent = this.blocks[item];
            }
          }
        }
      }
      this.question = parent;
    },
    async getCurrentQuestion() {
      if (this.attempt.status === 2) return false;
      if (this.question) {
        let parent_id = this.question.id;
        this.question = null;
        for (let item in this.blocks) {
          if (this.blocks[item].parentId === parent_id) {
            this.question = this.blocks[item];
            break;
          }
        }
      } else {
        this.question = null;
        for (let item in this.blocks) {
          if (!this.stories[this.blocks[item].id]) {
            this.question = this.blocks[item];
            break;
          }
        }
      }
      if (!this.question) {
        try {
          await api.profile.postQuestionnaireAttemptCompleted(this.attempt.id);
        } catch (error) {
          console.log(error);
        }
        this.attempt.status = 2;
        this.getCurrentQuestion();
      }
    },
    async saveStory(question_id, answers) {
      let payload = {
        attempt_id: this.attempt.id,
        question_id: question_id,
        answers: answers,
      };
      try {
        await api.profile.postQuestionnaireAttemptStory(payload);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
